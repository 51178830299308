import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"

import { withCalendly } from "../types/calendly"

import * as DK from "design-kit"

import {
  WrappedHead,
  WrappedBase,
} from "../shared-components/Shared/WrappedBase"
import Footer from "../shared-components/Footer"
import Navbar from "../shared-components/Navbar"

import { Hero } from "../views/ProtectionTypes/Hero"
import { TypesList } from "../views/ProtectionTypes/TypesList"
import { FAQs } from "../views/ProtectionTypes/FAQs"

import cloud3 from "../assets/images/tinted-clouds/cloud3.png"
import cloud4 from "../assets/images/tinted-clouds/cloud4.png"
import cloud5 from "../assets/images/tinted-clouds/cloud5.png"

import config from "../../config.json"

const GetStarted: React.FC = () => (
  <DK.GridRow>
    <section
      css={css`
        ${DK.column({
          widthMobile: 2,
          offsetMobile: 1,
          widthTablet: 2,
          offsetTablet: 2,
          widthDesktop: 4,
          offsetDesktop: 4,
        })}
      `}
    >
      <DK.PrimaryCTA
        text="Get started"
        onClick={() => {
          withCalendly(Calendly => {
            Calendly.initPopupWidget({
              url: "https://calendly.com/d/cmbn-dzn-vq4/chat-with-a-protection-expert?primary_color=006be6?hide_landing_page_details=1",
            })
          })
        }}
      />
    </section>
  </DK.GridRow>
)

const Layout = styled.div`
  margin: 0 auto;
  max-width: ${DK.maxWidth}px;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  gap: ${DK.vertical.xxl};
`

const FALLBACK_GRADIENT = `linear-gradient(
  0deg,
  ${DK.colours.gradientBase.sunshine} 0%,
  ${DK.colours.gradientBase.bubblegum} 50%,
  ${DK.colours.gradientBase.mistyViolet} 90%
)`

const Main = styled.main`
  position: relative;
  z-index: 0;
  color: ${DK.colours.offBlack};
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: ${FALLBACK_GRADIENT};
  overflow: hidden;
`

const Clouds: React.FC = () => (
  <React.Fragment>
    <div
      css={css`
        position: relative;
        z-index: 0;
        max-width: ${DK.maxWidth * 1.5}px;
        margin: 0 auto;
        padding: 0 530px;
      `}
    >
      <img
        src={cloud4}
        alt=""
        css={css`
          position: absolute;
          z-index: -1;
          display: none;
          width: 231px;
          top: 100px;
          left: -50px;
          ${DK.breakpoints.desktop`
            display: block;
          `}
        `}
      />

      <img
        src={cloud3}
        alt=""
        css={css`
          position: absolute;
          z-index: -1;
          display: none;
          width: 473px;
          top: 100px;
          right: -200px;
          ${DK.breakpoints.desktop`
            display: block;
          `}
        `}
      />
    </div>

    <div
      css={css`
        position: relative;
        z-index: 0;
        max-width: ${DK.maxWidth}px;
        margin: 0 auto;
      `}
    >
      <img
        src={cloud5}
        alt=""
        css={css`
          position: absolute;
          z-index: -1;
          display: none;
          width: 194px;
          top: 850px;
          left: 100px;
          ${DK.breakpoints.desktop`
            display: block;
          `}
        `}
      />
    </div>
  </React.Fragment>
)

const Parts = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${DK.vertical.l};
`

const PageContent: React.FC = () => (
  <React.Fragment>
    <Navbar theme="transparent-dark" />

    <Main id="main-content">
      <Clouds />
      <Layout>
        <Hero />
        <Parts>
          <TypesList />
          <GetStarted />
          <FAQs />
        </Parts>
      </Layout>
    </Main>

    <Footer />
  </React.Fragment>
)

export const Head: React.FC = () => (
  <WrappedHead
    metaTitle="Types of protection | Habito"
    metaDescription="Learn about the different types of protection - Life Cover, Critical illness Cover and Income Protection."
    canonicalUrl="https://www.habito.com/protection-types"
    noIndex={false}
    pageName="protection-types"
    intercom={true}
    config={config}
  >
    <link
      rel="preload"
      as="style"
      href="//assets.calendly.com/assets/external/widget.css"
    />
    <link
      rel="stylesheet"
      href="//assets.calendly.com/assets/external/widget.css"
    />
    <script async src="//assets.calendly.com/assets/external/widget.js" />
  </WrappedHead>
)

const ProtectionTypes: React.FC = () => (
  <WrappedBase>
    <PageContent />
  </WrappedBase>
)

export default ProtectionTypes
